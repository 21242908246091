<template>
  <div
    class="product-list-v2"
    da-expose-code="productListExposeCode"
  >
    <!-- S 头部 -->
    <div class="product-list-v2__header scrollfix-sub">
      <!-- S 面包屑 -->
      <BreadCrumb v-if="isShowBreadCrumb" />
      <!-- E 面包屑 -->

      <!-- S Promo Discount 顶部 Banner -->
      <PicksBanner
        v-if="isShowPicksBanner"
        :banner-data="picksBannerData"
      />
      <!-- E Promo Discount 顶部 Banner -->

      <!-- S Picks 导航 -->
      <PicksNav
        v-if="isShowPicksNav"
        :nav-data="picksNavData"
      />
      <!-- E Picks 导航 -->
      <!-- S 搜索纠错 新样式-->
      <SearchCorrect
        v-if="sum !== 0 && abtNoResultPage && showSearchCorrect"
        :is-suggest-correction="isSuggestCorrection && sum > 0 "
        :origin-search-word-text="originSearchWordText"
        @search-suggest-origin-word="searchSuggestOriginWord"
      />
      <!-- 搜索商品分层提示语 新展示位置 -->
      <ClientOnly>
        <SearchHierarchyTitle
          v-if="showHierarchyTitle"
          :not-priority="true"
          :top-style="true"
          :current-page="catInfo.page"
          :search-word="catInfo.keywords"
          :rec-tip-index-new="true"
          :language="language"
        />
      </ClientOnly>
      <!-- S 列表信息 -->
      <TopInfo 
        v-if="isCloudExpand && !isPicks && showTopInfo"
        :is-view-new="isViewNew"
        :is-suggest-correction="isSuggestCorrection && paramUseSuggestWord && isViewNew"
        :origin-search-word-text="originSearchWordText"
        @search-suggest-origin-word="searchSuggestOriginWord"
        @select-cloud-tag="handleFilterChange"
      />
      <!-- E 列表信息 -->
      <template v-if="sum > 0 && !isSearchEmpty">
        <!-- S 非商品搜索，服务类是的提示 -->
        <ServiceTypeTip v-if="isServiceType" />
        <!-- E 非商品搜索，服务类是的提示 -->
        <!-- S 优惠券 -->
        <ClientOnly>
          <CouponTip />
        </ClientOnly>
        <!-- E 优惠券 -->

        <template v-if="isCloudExpand && !abtFilterSelected && !isViewNew">
          <ClientOnly>          
            <!-- S 相关搜索 -->
            <RelateSearch :style-type="'top'" />
            <!-- E 相关搜索 -->
          </ClientOnly>
          <!-- S 排序 -->
          <SortBar 
            v-if="!isPicks && !abtFilterSelected" 
            @click-sort="handleFilterChange"
          />
          <!-- E 排序 -->
        </template>

        <!-- S 筛选栏 -->
        <!-- <TopFilter /> -->
        <!-- E 筛选栏 -->
      </template>
    </div>
    <!-- E 头部 -->

    <!-- S 搜索空结果页 -->
    <SearchCorrect v-if="isSearchEmpty && !hypernymProductInfo.list.length " />
    <!-- E 搜索空结果页 -->

    <!-- S 主区域 -->
    <div
      v-else
      class="product-list-v2__main"
      :class="{
        'product-list-v2__main_side': showSideFilter,
        'product-list-v2__main_side-ie': isFixed,
        'product-list-v2__main_ie': ie,
        'product-list-v2__main_top': abtFilterSelected,
        'product-list-v2__main_view-new': isViewNew,
      }"
    >
      <!-- S 筛选栏 -->
      <SideFilter
        v-if="showSideFilter"
        ref="sideFilter"
        :request-loading="request.loading"
        @ie-fixed="ieFixed"
        @filter-change="handleFilterChange"
      />
      <!-- E 筛选栏 -->

      <div class="product-list-v2__container">
        <template v-if="sum > 0">
          <!-- sortbar/标签云 -->
          <ViewNewCloudTag
            v-if="isViewNew"
            @select-cloud-tag="handleFilterChange"
          >
            <SortBar 
              :is-view-new="true"
              @click-sort="handleFilterChange"
            />
          </ViewNewCloudTag>

          <!-- S 列表信息 -->
          <TopInfo 
            v-if="showShortTopInfo && showTopInfo"
            @select-cloud-tag="handleFilterChange"
          />
          <!-- E 列表信息 -->

          <!-- S 非商品搜索，服务类是的提示 -->
          <ServiceTypeTip v-if="!isCloudExpand && isServiceType" />
          <!-- E 非商品搜索，服务类是的提示 -->

          <!-- S 优惠券 -->
          <template v-if="!isCloudExpand">
            <CouponTip />
          </template>
          <!-- E 优惠券 -->

          <template v-if="(!isCloudExpand || isPicks || abtFilterSelected) && !isViewNew">
            <ClientOnly>
              <!-- S 相关搜索 -->
              <RelateSearch 
                :filter-selected="abtFilterSelected && !showShortTopInfo"
                :style-type="'top'" 
              />
              <!-- E 相关搜索 -->
            </ClientOnly>
            <!-- S 排序 -->
            <SortBar 
              :filter-selected="abtFilterSelected && !showShortTopInfo"
              @click-sort="handleFilterChange" 
            />
            <!-- E 排序 -->
          </template>
        </template>
        
        <!-- S 搜索纠错 -->
        <SearchCorrect
          v-if="!abtNoResultPage && showSearchCorrect"
          :is-suggest-correction="isSuggestCorrection && sum > 0 "
          :origin-search-word-text="originSearchWordText"
          @search-suggest-origin-word="searchSuggestOriginWord"
        />
        
        <!-- E 搜索纠错 -->
        <!-- S 商品列表 -->
        <!-- 注意，小分页的时候不需要触发骨架屏 -->
        <ClientOnly v-if="request.loading && !isSearchEmpty && !request.isLoadMore">
          <ProductListSkeleton
            :num="15" 
          />
        </ClientOnly>
        <ProductList 
          v-else-if="!isSearchEmpty"
          ref="productListRef"
          :request="request"
          :hide-pagination="sum <= 120 && hypernymProductInfo.list.length > 0"
          :infinite-load-nodata="infiniteLoadNodata"
          @page-change="handlePageChange"
          @load-more="handleLoadMorePageChange"
        />
        <!-- E 商品列表 -->

        <!-- S 相关搜索 -->
        
        <!-- 列表底部 客户端渲染即可 -->
        <ClientOnly>
          <!-- 搜索框正下方 涉及到dom计算只能客户端-->
          <Teleport
            to=".j-related-search"
            :disabled="!isRelatedSearchUnderSearch"
          >
            <RelateSearch
              v-if="isRelatedSearchUnderSearch"
              style-type="underSearch"
              @get-related-search-show="underSearchRelatedSearchWordNum = $event"
            />
          </Teleport>
          <RelateSearch
            v-if="showViewNewRelatedSearch"
            style-type="viewNew"
            :has-image="isImageRelatedSearch"
            :filter-start-index="underSearchRelatedSearchWordNum"
          />
        </ClientOnly>
        <!-- E 相关搜索 -->

        <!-- S 上位词搜索商品列表 -->
        <ClientOnly>
          <transition name="slide-fade">
            <HypernymProductWrap 
              v-if="hypernymProductInfo.list.length"
              :style="{marginTop: isViewNew ? '20px' : ''}"
              :info="hypernymProductInfo"
              :search-goods-sum="sum"
              @change-page="fetchHypernyData"
            />
          </transition>
        </ClientOnly>
        <!-- E 上位词搜索商品列表 -->
      </div>
    </div>
    <!-- E 主区域 -->

    <!-- S 尾部 -->
    <ClientOnly>
      <div
        :class="{
          'product-list-v2__footer_side': showSideFilter && !isSearchEmpty,
        }"
        class="product-list-v2__footer"
      >
        <!-- S 反馈入口 -->
        <FeedBack
          v-if="!isSearchEmpty"
          :class="{
            'text-center': sum == 0,
          }"
          :style-type="'list'"
        />
        <!-- E 反馈入口 -->

        <!-- S 相关搜索 -->
        <RelateSearch
          v-if="!isViewNew"
          :style-type="'bottom'"
        />
        <!-- E 相关搜索 -->

        <!-- S 商品推荐位 -->
        <ProductRecommend :is-filter-list-page="hasFilter" />
        <!-- E 商品推荐位 -->

        <!-- S 选品底部 Banner -->
        <!-- cccx -->
        <CccxComp 
          v-if="cccxContextContent"
          :cccx-context="cccxContext"
          :content="[cccxContextContent]"
        />
        <!-- 老ccc -->
        <SelectionBanner 
          v-else
          :banner-block-name="2" 
        />
        <!-- E 选品底部 Banner -->

        <!-- S 底部说明 -->
        <FooterExplain />
        <!-- E 底部说明 -->
      </div>
    </ClientOnly>
    <!-- E 尾部 -->
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import { transformImg, replaceHrefSpecChar } from '@shein/common-function'
import lazyLoadComponent from './utils/lazy-load-component' // 组件懒加载
import { ClientOnly } from '@sheinfe/vue-client-only'
import schttp from 'public/src/services/schttp'
import BreadCrumb from './components/BreadCrumb'
import PicksBanner from './components/PicksBanner'
import PicksNav from './components/PicksNav'
// import TopFilter from './components/filter/TopFilter'
import SideFilter from './components/filter/SideFilter'
import ProductList from './components/ProductList'
import SkeletonBox from './components/SkeletonBox'
import FeedBack from './components/FeedBack'
import SearchCorrect from './components/SearchCorrect'
import RelateSearch from './components/RelatedSearch'
import SelectionBanner from './components/SelectionBanner'
import FooterExplain from './components/FooterExplain'
import { isFunction } from 'lodash'
import SortBar from './components/filter/SortBar'
import TopInfo from './components/TopInfo'
import CouponTip from './components/CouponTip'
import ServiceTypeTip from './components/ServiceTypeTip'
import ProductListSkeleton from './components/ProductListSkeleton'
import SearchHierarchyTitle from './components/SearchHierarchyTitle.vue'
import HypernymProductWrap from './components/hypernymProductWrap'
import ViewNewCloudTag from './components/ViewNewCloudTag'
import { ProductListAnalysis } from './analysis/index'
import { daEventCenter } from 'public/src/services/eventCenter'
import { transfromAndCutImg } from 'public/src/services/resource/index'
import { ScrollExpose } from 'public/src/pages/common/analysis/ScrollExpose'
import { isIE } from '../components/filter/common/utils'
import { dealProductsPretreatInfo } from 'public/src/services/goodsItemInfo/goodsPretreatInfo.js'
import mitt from 'mitt'
import { getSearchSourceBySearchType } from 'public/src/pages/common/search_words/const'
import searchWordsGlobalAnalysis from 'public/src/pages/common/biz_helper/gb_sw_analysis'
import { getGoodsUrl } from 'public/src/pages/common/utils/index.js'
import { getQueryString } from '@shein/common-function'
import buyBoxPriceMixin from 'public/src/pages/product_list_v2/mixins/buyBoxPriceMixin.js'
import { fetchProductListApi, getFetchParams, PRODUCT_LIST_URL_API, getPreFetchProductList } from '@/public/src/services/pre_requests/productList'
import { metricPageSuccess } from 'public/src/pages/common/business-monitor/common.js'

let daEventExpose = null
if (typeof window !== 'undefined') {
  daEventCenter.addSubscriber({ modulecode: '2-10' })
  daEventCenter.addSubscriber({ modulecode: '2-28' })
  daEventCenter.addSubscriber({ modulecode: '1-4-2' })
  daEventCenter.addSubscriber({ modulecode: '2-3' })
  daEventCenter.addSubscriber({ modulecode: '1-4-1' })
  daEventCenter.addSubscriber({ modulecode: '1-7-1' })
  daEventExpose = daEventCenter.getExposeInstance()
}

export default {
  name: 'PLV2',
  components: {
    SearchCorrect,
    BreadCrumb,
    PicksBanner,
    PicksNav,
    SortBar,
    TopInfo,
    // TopFilter,
    SideFilter,
    ProductList,
    ClientOnly,
    FeedBack,
    CouponTip,
    RelateSearch,
    ProductRecommend: lazyLoadComponent({
      componentFactory: () => import('./components/ProductRecommend'),
      loading: SkeletonBox,
    }),
    SelectionBanner,
    FooterExplain,
    ServiceTypeTip,
    SearchHierarchyTitle,
    ProductListSkeleton,
    HypernymProductWrap,
    ViewNewCloudTag,
    CccxComp: lazyLoadComponent({
      componentFactory: () => import('./components/CccxComp'),
      loading: SkeletonBox,
    }),
  },
  mixins: [buyBoxPriceMixin],
  props: {
    context: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      isFixed: false,
      ie: false,
      request: {
        type: '',
        query: { page: 1 },
        loading: false,
        isLoadMore: false,
      },
      hypernymProductInfo: {
        // 上位词查询的requst Info
        list: [],
        page: 1,
        limit: 120,
        sum: null, // 下游存在sum部位0，当listh为空情况
        keywords: '',
        loading: false
      },
      recommendListPageNum: null,
      underSearchRelatedSearchWordNum: 0, // 当前页面可能挂载2个搜了还搜（搜索底部 + 列表底部）,该变量用于划分搜了还搜展示的搜索词个数
      paramUseSuggestWord: true, // 建议纠错实验，使用建议词查询商品
      viewedGoods: [], // 主搜已经浏览的商品
      infiniteLoadNodata: false,
      requestInstance: null, // 针对搜索结果列表小分页功能 中断请求使用到,
      searchRequestStatus: '',
      searchRecRequestStatus: ''
    }
  },
  computed: {
    ...mapGetters([
      'catInfo',
      'fhContext',
      'googleContext',
      'goods',
      'sum',
      'searchKeywords',
      'currentCat',
      'parentCats',
      'goodsCrowId',
      'traceId',
      'dailyDates',
      'filterCates',
      'filterAttrs',
      'filterPrices',
      'searchCCCCard',
      'listFilterAbt',
      'atomicParams',
      'promotionInfoFromServer',
      'cccxConfig',
      'relateSearchWords',
      'searchTraceId',
      'topBanner',
      'picksMenuList',
    ]),
    ...mapState(['listAbtResult', 'SiteUID', 'results', 'language', 'RESOURCE_SDK']),
    abtFilterSelected() {
      return this.listAbtResult.FilterSelected?.p == 'Selected=True'
    },
    abtNoResultPage() {
      return this.listAbtResult?.NoResultPage?.p?.NoResultPage === 'new'
    },
    // 通栏标签云
    isCloudExpand() {
      return this.catInfo.type != 'picks' && !(this.abtNoResultPage && this.sum === 0)
    },
    // 当前是否为筛选之后的页面
    hasFilter() {
      return Object.keys(this.catInfo).some((key) => {
        const filterAttr = [
          'daily',
          'child_cat_id',
          'attr_values',
          'min_price',
          'max_price',
        ]
        return this.catInfo[key] && filterAttr.includes(key)
      })
    },
    isSearchEmpty() {
      // 页面类型为搜索页 && 搜索为空 && 非筛选
      // 空结果非为 筛选空 and 搜索空   搜索空包含筛选空
      return this.catInfo.type === 'search' && this.sum == 0 && !this.hasFilter
    },
    isShowPicksBanner() {
      return this.isPicks && this.topBanner && this.topBanner.length
    },
    picksBannerData() { 
      if (!this.topBanner || !this.topBanner.length) {
        return []
      }
      return this.topBanner
    },
    isShowPicksNav() {
      return this.picksMenuList && this.picksMenuList.length
    },
    picksNavData() {
      if (!this.picksMenuList || !this.picksMenuList.length) {
        return []
      }
      return this.picksMenuList
    },
    isPicks() {
      return this.catInfo.type == 'picks'
    },
    showSideFilter() {
      return (
        Object.keys(this.dailyDates).length ||
        this.filterCates?.children?.length ||
        this.filterAttrs?.length ||
        this.filterPrices?.min_price !== this.filterPrices?.max_price
      )
    },
    isServiceType() {
      return this.searchCCCCard?.wordType === 0
    },
    // 短版的topInfo
    showShortTopInfo() {
      return !this.isCloudExpand && !this.isPicks
    },
    showTopInfo() {
      return this.listAbtResult?.labelColumn?.p?.labelColumn !== 'hide'
    },
    resourceSDK () {
      return this.RESOURCE_SDK || {}
    },
    // 布局视觉优化【pageId=1219703303】
    isViewNew () { 
      return this.listAbtResult?.PcListView?.p?.PcListViewNew === 'B'
    },
    // 搜了还搜置顶
    isRelatedSearchUnderSearch () {
      const isNewSearchUI = this.listAbtResult?.PCAlltabCategory?.p?.Alltab_head === 'showall&newhead&Enlargesearch'
      const isUnderSearch = this.listAbtResult?.RelatedSearchNew?.p?.RelatedSearchLocNew === 'top'
      const hasRelateSearchWords = this.relateSearchWords?.length // 其实组件内部做了处理但是控制不到外面的teleport 会引起teleport渲染报错
      return isUnderSearch && this.isViewNew && isNewSearchUI && hasRelateSearchWords &&
       this.sum > 0
    },
    // 搜了还搜是否带图片
    isImageRelatedSearch () {
      // 是否都有图片
      const hasImage = this.relateSearchWords.every(item => item.thumbnail)
      return this.isRelatedSearchUnderSearch && hasImage
    },
    showViewNewRelatedSearch() {
      if (!this.isViewNew) {
        return false // 未命中新布局 不展示
      } else {
        // 命中相关搜索位于搜索框底部(置顶) 如果顶部没有展示完 则展示剩余的
        if (this.isRelatedSearchUnderSearch) {
          return this.underSearchRelatedSearchWordNum && this.underSearchRelatedSearchWordNum < this.relateSearchWords.length
        } else {
          return true
        }
      }
    },
    // 是否展示第一通道无结果样式
    showHierarchyTitle() {
      if(this.listAbtResult?.SearchRecTips?.p !== 'test_group' || this.listAbtResult?.NoResultPage?.p?.RecTipsPage !== 'new' || this.catInfo.search_type == 'store' ) {
        return false
      }
      const index =  this.goods.findIndex(
        (item) => (`${item.ext?.recall_mark}`.split('_') || [])[1] > 1
      )
      return index === 0 ? true : false
    },
    // 是否展示面包屑【新样式&搜索结果页下不展示】
    isShowBreadCrumb () {
      if (this.isViewNew && this.catInfo.pageName === 'page_search')  {
        return false
      }
      return true
    },
    cccxContext() {
      return this.cccxConfig?.bottom_list_zone || {}
    },
    cccxContextContent() {
      return this.cccxContext?.content?.[0]
    },
    isSuggestCorrection() {
      return this.listAbtResult?.searchcorrect?.p?.correction_type === 'suggestcorrection'
    },
    originSearchWordText () {
      const { SHEIN_KEY_PC_28745: hint } = this.language
      const { keywords } = this.catInfo
      if (!hint) {
        return ''
      }
      return hint.replace('{0}', `<a href="javascript:;" 
      class="link" 
      da-event-click="1-4-1-19"
      data-src-identifier="st=19\`sc=${keywords}\`sr=0\`ps=0"
      >${keywords}</a>`)
    },
    showSearchCorrect () {
      if (this.isSuggestCorrection && this.isViewNew) {
        // 命中建议纠错 & 命中列表布局优化，不展示搜索纠错模块
        return false
      }
      return this.catInfo.type === 'search' && this.catInfo.keywords && !this.hasFilter && this.paramUseSuggestWord
    },
  },
  watch: {
    $route: {
      handler: function() {
        if (this.isSuggestCorrection) {
          this.setParamUseSuggestWord()
        }
      },
      immediate: true
    },
  },
  created() {
    const contextForSSR = this.$store.state
    if (contextForSSR){
      this.resolveData(contextForSSR)
    }
    if(contextForSSR.sum < this.listAbtResult?.SearchPageSize?.p?.sub_page_size) {
      this.infiniteLoadNodata = true
    }
  },
  beforeMount() {
    const {
      searchKeywords,
      catInfo,
      goods,
      currentCat,
      parentCats,
    } = this
    // emarsys 推荐
    ProductListAnalysis.pushEmarsysCommend({ catInfo, currentCat, parentCats })
    window.TPM?.publish('viewlist', {
      cat_info: catInfo,
      goods,
      searchKeywords,
    })
  },
  mounted() {
    this.ie = isIE()
    if (!window.appEventCenter) {
      window.appEventCenter = mitt()
    }
    window.appEventCenter.on('recommendListPageNum', total => {
      // 刷新推荐的商品num
      this.recommendListPageNum = total
      this.refreshPageAnalysis(true)
    })
  
    getPreFetchProductList().then((data) => {
      const newGoods = data?.goods?.filter(item => !this.goods.some(_ => _.goods_id === item.goods_id)) || []
      if (!newGoods.length) {
        this.handleStrategyPushGoods() // 首屏直出商品处理
        return
      }

      this.dealingSplitGoods({ goods: newGoods, promotionInfoFromServer: this.promotionInfoFromServer, slicePushCount: 4 })
    })

    if (this.sum <= 120) {
      // firstLoad的时候因为埋点需要，已经去请求过上位词了，因此有上位词的时候再去请求
      this.fetchHypernyData()
    }
  },
  activated() {
    // 页面跳转或者离开的情况，重置页面请求code上报的值
    this.searchRequestStatus = ''
    this.searchRecRequestStatus = ''
  },
  /**
   * 服务端直出 && 客户端公共方法
   */
  provide() {
    const context = this.context
    return {
      replaceHrefSpecChar,
      transformImg,
      getGoodsUrl(goodsName = '', goodsId = '', catId = '') {
        if (typeof window !== 'undefined' && isFunction(getGoodsUrl))
          return getGoodsUrl(goodsName, goodsId, catId)
        if (context) return context.getGoodsUrl(goodsName, goodsId, catId)
        return ''
      },
      scrollExpose: new ScrollExpose({ exposeRatio: 0.3 }),
      hideGoodsNum: `${this.listAbtResult?.HideGoodsNum?.p}` !== 'false',
      cutImg: this.cutImg,
      isSupportCropImage: this.RESOURCE_SDK?.isSupportCropImage
    }
  },
  methods: {
    ...mapActions(['dealingNewData']),
    ...mapMutations(['resetState', 'dealingSplitGoods']),
    handleStrategyPushGoods() {
      const { slice_goods, promotionInfoFromServer } = this.results || {}
      this.dealingSplitGoods({ goods: slice_goods, promotionInfoFromServer })
    },
    ieFixed(isFixed) {
      this.isFixed = isFixed
    },
    // 筛选
    handleFilterChange({ queryObj }) {
      this.request.query = {
        ...this.request.query,
        ...queryObj,
        page: 1
      }
      this.fetchData('refresh')
    },
    // 翻页
    handlePageChange({ queryObj }) {
      this.request.query = {
        ...this.request.query,
        ...queryObj
      }
      this.fetchData('pageChange')
    },
    // 小分页翻页(防止后面扩展，单独拎出), 把fetchData里的包进来了
    async handleLoadMorePageChange({ queryObj }) {
      // 每页最多120条
      if (this.viewedGoods.length >= 120) {
        return
      }
      this.request.query = {
        ...this.request.query,
        ...queryObj
      }
      
      this.request.type = 'pageChange'
      this.request.loading = true
      this.request.isLoadMore = true // 给骨架屏用
      this.requestInstance = new SchttpAbortCon() // 中断请求用
      const data = await fetchProductListApi(this.getFetchData(), { signal: this.requestInstance.signal })
      this.requestInstance = null // 一次结束就至空
      data && this.resolveData({ results: data })
      const { searchResultInterfaceCode } = data || {}
      this.searchRequestStatus = searchResultInterfaceCode || '4_1001' // 拿不到的情况下认为是请求中间层异常
      this.refreshPageAnalysis(true)
    },
    async fetchData(requestType) {
      this.request.type = requestType
      this.request.isLoadMore = false
      this.request.loading = true
      // 点击了翻页或者筛选，此时小分页请求还未返回到情况处理,丢掉小分页的请求
      if(this.requestInstance) {
        this.requestInstance?.abort()
      }
      if(this.viewedGoods.length > 0) {
        this.viewedGoods = []
      }
      this.request.query.isPageLoadMore = false // 通过fetchData请求的，都没有小分页
      try {
        const data = await fetchProductListApi(this.getFetchData())
        const { searchResultInterfaceCode } = data || {}
        this.searchRequestStatus = searchResultInterfaceCode || '4_1001' // 拿不到的情况下认为是请求中间层异常
        this.refreshPageAnalysis(true)
        this.resolveData({ results: data })
      } catch (error) {
        console.log(error)
      }
    },
    // 获取参数时需要的数据
    getFetchData() {
      return {
        catInfo: this.catInfo,
        request: this.request,
        searchKeywords: this.searchKeywords,
        atomicParams: this.atomicParams,
        viewedGoods: this.viewedGoods,
      }
    },
    async resolveData ({ results, language }) {
      this.requestInstance = null // 小分页用一次结束就至空 
      this.request.loading = false
      this.request.isLoadMore = false
      if (results.cat_info.requestType == 'firstLoad') {
        this.resetState({
          payload: { ...results, language }
        })

        if (typeof window !== 'undefined') {
          // 业务监控日志 - 页面打开成功次数累加
          metricPageSuccess({
            page: results.cat_info.pageName,
            status: results?.goods?.length > 0 ? '1' : '0',
          }, {
            ...results.cat_info
          })
          if (results?.goods?.length === 0 && results?.cat_info?.pageName === 'page_goods_group') {
            schttp({
              method: 'POST',
              url: '/api/productList/reportLog',
              data: {
                catInfo: results?.cat_info || {},
                UA: navigator.userAgent,
              }
            })
          }
        }
      }
      this.dealingNewData({ results })
      this.updateViewedGoods(results.goods)
      if (typeof window === 'undefined') return

      if (this.request.type !== 'pageChange') {
        getPreFetchProductList().then(() => {
          this.refreshPageAnalysis()
        })
      }
      await this.$nextTick()
      this.$refs.productListRef?.setElAttr('all')
      this.getBuyBoxPrices(results.goods)
    },
    // pv埋点
    refreshPageAnalysis(onlyUpdateData = false) {
      let result_type = getQueryString({ key: 'word_type' })
      if (result_type === '20') {
        searchWordsGlobalAnalysis.set({
          result_type: 20,
          pagefrom: 'AdsSearch'
        })
      }
      // 重置back-to-up曝光
      daEventExpose.reset('backtopExpose')
      const {
        searchKeywords,
        catInfo,
        fhContext,
        googleContext,
        sum,
        currentCat,
        goodsCrowId,
        listAbtResult,
        parentCats,
        traceId,
        hypernymProductInfo,
        recommendListPageNum,
        searchTraceId,
        isHypernymGoods,
        searchRequestStatus,
        searchRecRequestStatus
      } = this
      const recResultCount = typeof hypernymProductInfo.sum === 'number' ? hypernymProductInfo.sum : '-'
      listAbtResult['listFilterAbt'] = this.listFilterAbt
      const recommend_count = catInfo.search_type !== 'store' ? { 'recommend_count': typeof recommendListPageNum === 'number' ? recommendListPageNum : '-' } : null
      const pageParamExtend = {
        rec_result_count: recResultCount,
        ...recommend_count,
        trace_id: searchTraceId,
      }
      const saPageInfoParam = {
        catInfo,
        fhContext,
        googleContext,
        currentCat,
        searchKeywords,
        sum,
        goodsCrowId,
        listAbtResult,
        traceId,
        parentCats,
        pageParamExtend,
        isHypernymGoods,
        searchRequestStatus,
        searchRecRequestStatus
      }
      ProductListAnalysis.init(saPageInfoParam)
      if (onlyUpdateData) {
        // init更新了window.SaPageInfo,但是没有更新localStorage的，local里的是在sendPageView方法里更新的
        sa('set', 'setPageData', window.SaPageInfo)
      } else {
        // 发送 pv
        ProductListAnalysis.sendPageView()
      }
    },

    cutImg (imgUrl, designWidth, exp) {
      const { deviceData = '', isSupportWeb = '', isSupprotCut = false } = this.resourceSDK

      const cutData = {
        deviceData,
        isSupportWebp: Boolean(isSupportWeb),
        isSupprotCut,
        imgUrl,
        designWidth: Number(designWidth),
        exp,
      }

      return transfromAndCutImg(cutData)
    },
    // 上位词查询相关
    async fetchHypernyData(page = 1) {
      try {
        const { limit, keywords } = this.hypernymProductInfo
        let hPKeywords = keywords || this.catInfo.keywords
        if (!hPKeywords) {
          return
        }
        this.hypernymProductInfo.loading = true
        const params = {
          ...getFetchParams(this.getFetchData()),
          isHypernymGoods: true,
          page,
          limit,
          hPKeywords,
          market_code: getQueryString({ key: 'market_code' })
        }
        const { hypernymProduct, goods, searchResultInterfaceCode } = await schttp({
          url: PRODUCT_LIST_URL_API,
          params,
        })
        this.searchRecRequestStatus = searchResultInterfaceCode || '4_1001' // 拿不到的情况下认为是请求中间层异常
        const newGoods = dealProductsPretreatInfo({
          products: goods,
          promotionInfoFromServer: this.promotionInfoFromServer,
          language: this.language,
        })
        this.hypernymProductInfo = {
          ...hypernymProduct,
          list: newGoods,
          loading: false,
          sum: this.hypernymProductInfo.page === 1 ? hypernymProduct.sum : this.hypernymProductInfo.sum // 只更新一次 sum，避免nextpage接口挂了以后，sum从有值变成无
        }
        this.refreshPageAnalysis(true)
      } catch(err) {
        console.log('hypernymProductListFetchError', err)
      }
    },
    searchSuggestOriginWord(event) {
      const { stringifyQueryString } = require('@shein/common-function')
      if (event?.target.classList.contains('link')) {
        searchWordsGlobalAnalysis.set({
          result_type: 19
        })
        // 跳转结果页
        const { keywords } = this.catInfo
        const oldParam = new URLSearchParams(location.search)
        let ici = oldParam.get('ici')
        const iciArr = ici.split('`')
        iciArr[1] = 'CorrectOriginalWord'
        iciArr[iciArr.length - 1] = 'PageSearch'
        ici = iciArr.join('`')
        const optionParams = {
          ici,
          search_source: getSearchSourceBySearchType(19),
          src_module: 'search',
          src_identifier: `st=19\`sc=${keywords}\`sr=0\`ps=0`,
          src_tab_page_id: window?.getSaPageInfo?.tab_page_id || '',
          dont_use_suggest_word: 1,
          search_type: 'all'
        }
        location.href = `/pdsearch/${encodeURIComponent(keywords)}/?${stringifyQueryString({
          queryObj: { ...optionParams },
        })}`
      }
    },
    setParamUseSuggestWord() {
      if (typeof window !== 'undefined') {
        const search = new URLSearchParams(window.location.search)
        this.paramUseSuggestWord = search.get('dont_use_suggest_word') !== '1'
      }
    },
    // 记录viewedGoods
    updateViewedGoods(goods) {
      // 再次获取没数据了,说明当前页的小分页逻辑结束
      if(!goods?.length) {
        this.infiniteLoadNodata = true
      } else {
        this.infiniteLoadNodata = false
      }
      if (this.viewedGoods.length < 120) {
        let viewedGoods = []
        viewedGoods = goods.map(goodItem => {
          const goodsId = goodItem.goods_id
          const spuId = goodItem.productRelationID
          return goodsId + '-' + spuId
        })
        this.viewedGoods = [...new Set([...this.viewedGoods, ...viewedGoods])]
      }
    },
  },
}
</script>

<style lang="less">
.product-list-v2 {
  margin: 0 auto;
  padding: 0 50px;
  max-width: 2300px;
  &__main {
    display: flex;
    &_left {
      width: 220px;
      .margin-r(20px);
    }
    &_top {
      padding-top: 32px;
    }
  }
  &__header {
    position: relative;
    padding: 10px 0 0;
  }
  &__header-sort {
    position: relative;
    height: 30px;
    padding: 16px 0;
    box-sizing: content-box;
  }
  &__container {
    position: relative;
    width: 100%;
    min-height: 100vh;
  }
}
.product-list-v2__main_side {
  .product-list-v2__container {
    .padding-l(20px);
  }
}
.product-list-v2__footer_side {
  .padding-l(230px);
}

.product-list-v2__main_view-new {
  padding-top: 0;
}

/* stylelint-disable-next-line selector-class-pattern */
.slide-fade-enter-active {
  transition: all .8s ease;
}
/* stylelint-disable-next-line selector-class-pattern */
.slide-fade-enter, .slide-fade-leave-to {
  opacity: 0;
}
</style>
