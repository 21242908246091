const getters = {
  locals: (state) => {
    return state?.locals || {}
  },
  goods: (state) => {
    return state?.Results?.goods || []
  },
  sheinClubInfo: (state) => {
    return state?.results?.sheinClubInfo || {}
  },
  cccConfig: (state) => {
    const cccConfig = state?.results?.cccConfig || {}
    
    return {
      ...cccConfig,
      ...(cccConfig?.pcStandardView || {})
    }
  },
  cccxConfig: (state) => {
    return state?.results?.cccxConfig || {}
  },
  cccxFilterConfig: (state) => {
    return state?.results?.cccxFilterConfig || {}
  },
  mallInfo: (state) => {
    return state?.results?.mallInfo || []
  },
  catInfo: (state) => {
    return state?.results?.cat_info || {}
  },
  fhContext: (state) => {
    return state?.results?.fhContext || {}
  },
  googleContext: (state) => {
    return state?.results?.googleContext || {}
  },
  tracking: (state) => {
    return state?.results?.tracking || {}
  },
  request_ext: (state) => {
    return state?.results?.request_ext || {}
  },
  listAbtResult: (state) => {
    return state?.listAbtResult || {}
  },
  navCrumbs: (state) => {
    return state?.results?.navCrumbs || []
  },
  currentCat: (state) => {
    return state?.results?.currentCat || {}
  },
  parentCats: (state) => {
    return state?.results?.parentCats || {}
  },
  cccSeo: (state) => {
    return state?.results?.cccSeo || {}
  },
  cccListOperation: (state) => {
    return state?.results?.cccListOperation || {}
  },
  sum: (state) => {
    return state?.results?.sum || 0
  },
  cloudTags: (state) => {
    return state?.results?.cloudTags || []
  },
  filterData: (state) => {
    return {
      dailyDates: state?.results?.dailyDates || {},
      filterCates: state?.results?.filterCates || {},
      filterTsps: state?.results?.filterTsps || [],
      filterAttrs: state?.results?.filterAttrs || [],
      filterPrices: state?.results?.filterPrices || {}
    }
  },
  dailyDates: (state) => {
    return state?.results?.dailyDates || {}
  },
  filterCates: (state) => {
    return state?.results?.filterCates || {}
  },
  selectedCate: (state) => {
    return state?.results?.selectedCate || []
  },
  filterTsps: (state) => {
    return state?.results?.filterTsps || []
  },
  selectedTsps: (state) => {
    return state?.results?.selectedTsps || []
  },
  filterAttrs: (state) => {
    return state?.results?.filterAttrs || []
  },
  filterHotAttrs: (state) => {
    return state?.results?.hotFilterAttrs || {}
  },
  filterPrices: (state) => {
    return state?.results?.filterPrices || {}
  },
  selectedAttrs: (state) => {
    return state?.results?.selectedAttrs || []
  },
  searchKeywords: (state) => {
    return state?.results?.searchKeywords || {}
  },
  relateSearchWords: (state) => {
    return state?.results?.relateSearchWords || []
  },
  picksMenuList: (state) => {
    return state?.results?.picksMenuList || []
  },
  topBanner: (state) => {
    return state?.results?.topBanner || {}
  },
  goodsCrowId: (state) => {
    return state?.results?.goodsCrowId || ''
  },
  traceId: (state) => {
    return state?.results?.trace_id || ''
  },
  searchTraceId: (state) => {
    return state?.results?.searchTraceId || ''
  },
  // 实际返回给客户端的商品总数
  sumForPage: (state) => {
    return state?.results?.sumForPage || 0
  },
  searchCCCCard: (state) => {
    return state.results.searchBannerCard?.searchCCCCard  || null
  },
  searchStoreCard: (state) => {
    return state.results.searchBannerCard?.searchStoreCard  || null
  },
  listFilterAbt: (state) => {
    return state?.results?.filterAbtParam?.abt || {}
  },
  filterAbtParam: (state) => {
    return state?.results?.filterAbtParam || {}
  },
  promotionInfoFromServer: (state) => {
    return state?.results?.promotionInfoFromServer || {}
  },
  atomicParams: (state) => {
    return state?.results?.atomicParams || {}
  },
  isCccxFilterConfig: (state) => {
    return state?.results?.shouldUseCccxFilterChain || false
  },
  // 商卡配置
  cardConfig: (state) => {
    const cardConfig = state?.results?.cardConfig
    return cardConfig || {}

  },
  recommendPanelFields: (state) => {
    const recommendPanelFields = state?.results?.recommendPanelFields
    
    return recommendPanelFields || {}
  },
}

export default getters
